<template>
  <v-sheet
    class="px-4 pt-6 pb-8 pa-sm-10 elevation-1 d-flex flex-column flex-grow-1 fill-height"
    min-width="320px"
    :rounded="$vuetify.display.smAndUp ? 'lg' : '0'"
    :class="[{ 'bg-ui-kit-text': isDarkThemeApplied }]"
  >
    <div class="mb-4 flex-grow-0">
      <v-img
        width="100%"
        height="100%"
        v-bind="imgProps"
      />
    </div>

    <div class="d-flex flex-column fill-height">
      <div :class="['text-h6', { 'text-ui-kit-bg-gray-0': isDarkThemeApplied } ]">
        {{ title }}
      </div>
      <div class="mt-2 d-flex align-center">
        <v-icon
          class="mr-2"
          :color="isDarkThemeApplied ? 'ui-kit-bg-gray-60' : 'ui-kit-icon-secondary'"
        >
          {{ dateIcon }}
        </v-icon>
        <span
          class="text-body-2"
          :class="{ 'text-ui-kit-bg-gray-40': isDarkThemeApplied }"
        >
          {{ dateText }}
        </span>
      </div>
      <v-sheet
        class="my-4 mb-sm-6 text-body-1"
        :color="isDarkThemeApplied ? 'ui-kit-text' : ''"
        :class="isDarkThemeApplied ? 'text-ui-kit-bg-gray-60' : 'text-ui-kit-text-secondary'"
      >
        <slot name="content-text">
          <span v-html="contentText" />
        </slot>
      </v-sheet>
      <v-btn
        class="mt-auto flex-grow-0"
        v-bind="buttonProps"
        variant="flat"
        :color="isDarkThemeApplied ? 'ui-kit-bg-gray-0' : 'primary'"
        block
        rounded
        @click="emit('click:button')"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </v-sheet>
</template>

<script setup lang="ts">

import type { PropType } from 'vue'
import { useTheme } from 'vuetify'

const props = defineProps({
  title: { type: String, required: true },
  dateText: { type: String, required: true },
  dateIcon: { type: String, default: 'ui-icon-clock' },
  contentText: { type: String, default: '' },
  buttonText: { type: String, required: true },
  imgProps: { type: Object as PropType<Record<string, unknown>>, required: true },
  buttonProps: { type: Object as PropType<Record<string, unknown>>, default: () => ({}) },
  dark: { type: Boolean, default: false },
})

const emit = defineEmits(['click:button'])

const theme = useTheme()
const isDarkThemeApplied = computed(() => theme.current.value.dark || props.dark)
</script>
