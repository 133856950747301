<template>
  <div>
    <section>
      <Heading />
    </section>
    <v-container class="d-flex flex-column pa-0">
      <section>
        <AwardStages :class="sectionMargin" />
      </section>
      <section
        id="how-it-was"
        v-intersect.quiet="{
          handler: getIntersectFunction('how-it-was'),
          options: {
            threshold: 0.1
          }
        }"
      >
        <HowItWas :class="sectionMargin" />
      </section>
      <section>
        <QuestionsAndAnswers :class="sectionMargin" />
      </section>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import Heading from '~/features/Home/components/Heading.vue'
import AwardStages from '~/features/Home/components/AwardStages.vue'
import HowItWas from '~/features/Home/components/HowItWas.vue'
import QuestionsAndAnswers from '~/features/Home/components/QuestionsAndAnswers.vue'

const $route = useRoute()
const $router = useRouter()

const sectionMargin = 'mt-12 mt-sm-16 pt-sm-1'

function getIntersectFunction(hash: string) {
  return (isIntersecting: boolean) => onIntersect(hash, isIntersecting)
}

async function onIntersect(hash: string, isIntersecting: boolean) {
  if (isIntersecting && $route.hash !== `#${hash}`) {
    await $router.replace({ hash: `#${hash}`, replace: true, params: { _disableAutoScrollToHash: 'true' } })
  } else if (!isIntersecting && $route.hash === `#${hash}`) {
    await $router.replace({ hash: undefined, replace: true, params: { _disableAutoScrollToHash: 'true' } })
  }
}
</script>
