<template>
  <div>
    <div class="px-2 px-md-0">
      <h2 class="text-h5">
        Как это было
      </h2>
      <ChipsBar
        v-model="howItWasYear"
        class="mt-4 mx-n4 mx-md-0"
        :items="chipsBarItems"
        mandatory
      />
    </div>
    <v-card class="mt-6 px-0 py-10 pt-sm-14 pb-sm-12">
      <VideoWithGallery
        :selected-how-it-was="selectedHowItWas"
        :how-it-was-year="howItWasYear"
      />

      <template v-if="isSponsoredYear">
        <v-divider class="my-8 my-sm-14" />
        <Partners
          class="px-4 px-md-12"
          :selected-year="howItWasYear as keyof typeof SPONSORS"
        />
      </template>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { HOW_IT_WAS, SPONSORS } from '~/features/Home/constants'
import Partners from '~/features/Home/components/Partners.vue'
import VideoWithGallery from '~/features/Home/components/VideoWithGallery.vue'

const howItWasYears = computed(() => Object
  .keys(HOW_IT_WAS)
  .map(Number)
  .sort((a, b) => b - a) as (keyof typeof HOW_IT_WAS)[])

const chipsBarItems = computed(() => howItWasYears.value.map(year => ({ value: year, text: `${year} год` })))

const howItWasYear = ref(howItWasYears.value[0])

const selectedHowItWas = computed(() => HOW_IT_WAS[howItWasYear.value])

const isSponsoredYear = computed(() => Object.prototype.hasOwnProperty.call(SPONSORS, howItWasYear.value))
</script>
