<template>
  <div>
    <div class="pt-6 heading-section">
      <v-container class="d-flex align-center flex-column flex-md-row">
        <div class="mr-4 text-center text-md-left">
          <h1 class="text-landing-h5 text-sm-landing-h1 text-no-wrap award-title">
            Всероссийская премия<br />
            ПроДокторов {{ mainStore.awardYear }}
          </h1>
          <h2
            v-if="tagline"
            class="mt-2 text-ui-kit-text-secondary text-body-1 text-sm-landing-h6"
          >
            {{ tagline }}
          </h2>
        </div>
        <v-img
          v-bind="heroesImgProps"
          src="/graphics/hero.png"
          height="364"
          aspect-ratio="1.42931671514"
          position="center top"
          :alt="`Премия ПроДокторов ${mainStore.awardYear}`"
          cover
        />
      </v-container>
    </div>

    <v-container class="py-0">
      <v-card
        class="px-4 py-10 py-sm-14 d-flex align-center bg-white text-center flex-column"
        :tile="!$vuetify.display.mdAndUp"
      >
        <v-img
          src="/graphics/five-stars.svg"
          width="112"
          height="16"
          alt="Пять звёзд"
        />
        <h2 class="mt-6 mt-sm-4 text-landing-h6 text-sm-h5 text-ui-kit-text-secondary">
          ПроДокторов награждает лучших врачей и лучшие клиники по&nbsp;отзывам пациентов
        </h2>
        <div
          v-if="counts && isExistCounts"
          class="mt-6 mt-sm-8 w-100 d-flex justify-space-around justify-sm-center"
        >
          <v-sheet
            class="text-center"
            :width="$vuetify.display.smAndUp ? 206 : 114"
          >
            <div class="text-h3">
              {{ counts.award_doctors_count }}
            </div>
            <div class="mt-1 text-ui-kit-text-secondary">
              {{ getDoctorsTitle(counts.award_doctors_count) }}
            </div>
          </v-sheet>
          <v-divider
            class="mx-sm-6"
            vertical
          />
          <v-sheet
            class="text-center"
            :width="$vuetify.display.smAndUp ? 206 : 114"
          >
            <div class="text-h3">
              {{ counts.award_lpus_count }}
            </div>
            <div class="mt-1 text-ui-kit-text-secondary">
              {{ getLpusTitle(counts.award_lpus_count) }}
            </div>
          </v-sheet>
        </div>
        <v-btn
          v-if="isRulesButtonVisible"
          class="mt-4 text-primary"
          color="ui-kit-bg-primary"
          variant="flat"
          rounded
          @click="onOpenRulesClick"
        >
          Положение о Премии {{ mainStore.awardYear }}
        </v-btn>
      </v-card>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { TAGLINES, DOCS } from '~/constants/data'

const display = useDisplay()
const mainStore = useMainStore()

const isRulesButtonVisible = computed(() => mainStore.awardYear in DOCS)

const heroesImgProps = computed(() => display.mdAndUp.value
  ? {
      width: '80vw',
      maxWidth: '520px',
      style: { left: '-20px' },
    }
  : {
      width: '100%',
      maxWidth: '600px',
    })

const tagline = computed(() => {
  if (mainStore.awardYear in TAGLINES) {
    return TAGLINES[mainStore.awardYear as keyof typeof TAGLINES]
  }

  return ''
})

const counts = computed(() => mainStore.indexPage)

const isExistCounts = computed(() => mainStore.awardState !== 'announcement' &&
    (counts.value?.award_doctors_count || counts.value?.award_lpus_count))

function onOpenRulesClick() {
  openRulesDocument(mainStore.awardYear)
}

function getWord(count: number, declension: Declension) {
  return pluralize(count, declension, false)
}

function getLpusTitle(count: number) {
  return `${getWord(count, wordBestFemaleDeclension)} ${getWord(count, clinicDeclension)}`
}

function getDoctorsTitle(count: number) {
  return `${getWord(count, wordBestMaleDeclension)} ${getWord(count, doctorDeclension)}`
}
</script>

<style lang="scss" scoped>
@use 'vuetify/settings';

.award-title {
  position: relative;
  z-index: 1;
}

.heading-section {
  @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
    margin-bottom: 100px;
  }
}
</style>
