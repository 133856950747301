<template>
  <div class="partners">
    <h2 class="mb-6 text-h5 text-sm-center">
      Партнёры
    </h2>
    <div class="gc-4 gc-sm-6 gr-2 gr-sm-6 partners__cards">
      <CardSponsor
        v-for="item in selectedSponsors"
        :key="item.title"
        class="cursor-pointer"
        :title="item.title"
        :img-props="item.imgProps"
        @click="onSponsorClick(item.href)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { SPONSORS } from '~/features/Home/constants'

const props = defineProps({
  selectedYear: { type: Number as PropType<keyof typeof SPONSORS | undefined>, default: undefined },
})

const sponsorsYears = (Object.keys(SPONSORS).map(Number) as (keyof typeof SPONSORS)[]).sort((a, b) => b - a)
const sponsorsYear = sponsorsYears[sponsorsYears.length - 1]

const selectedSponsors = computed(() => SPONSORS[props.selectedYear ? props.selectedYear : sponsorsYear])

function onSponsorClick(href: string) {
  window.open(href, '_blank')
}
</script>

<style lang="scss" scoped>
.partners {
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
  }
}
</style>
