<template>
  <div class="px-4 px-md-12">
    <v-img
      v-ripple
      aspect-ratio="1.8"
      class="rounded"
      content-class="d-flex align-center justify-center"
      :srcset="`
          ${video.poster.src} 600w,
          ${video.poster.srcHq} 1024w
      `"
      :src="video.poster.srcHq"
      :alt="video.poster.alt"
      :class="{ 'cursor-pointer': $device.isDesktop }"
      eager
      @click="onVideoClick"
    >
      <v-btn
        color="white"
        variant="flat"
        icon
      >
        <v-icon
          color="primary"
          icon="ui-icon-player-play"
        />
      </v-btn>
    </v-img>
    <v-row
      class="mt-sm-3 mx-sm-n3 mt-1 mx-n1"
      no-gutters
    >
      <v-col
        v-for="(image, index) in images"
        :key="image.src"
        sm="4"
        cols="6"
        class="pa-sm-3 pa-1"
      >
        <v-img
          aspect-ratio="1"
          class="rounded"
          min-width="140"
          min-height="140"
          :class="{ 'cursor-pointer': $device.isDesktop }"
          :src="image.src"
          :alt="image.alt"
          @click="onImageClick(index)"
        />
      </v-col>
    </v-row>
    <div
      v-if="isForeignAgentYear"
      class="mt-5 mt-sm3 text-body-1 text-ui-kit-disabled"
    >
      * Минюст РФ признал иноагентом
    </div>
    <v-dialog
      v-model="isDialogShown"
      :width="$vuetify.display.xs ? '100%' : 830"
      :fullscreen="$vuetify.display.xs"
      :persistent="$vuetify.display.xs"
    >
      <v-card class="fill-height d-flex flex-column overflow-auto">
        <v-toolbar height="56">
          <v-btn
            icon="ui-icon-close-not-a-circle"
            @click="isCarouselShown = isVideoShown = false"
          />
          <v-toolbar-title>
            <span class="text-h6">{{ title }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <div class="py-5 flex-grow-1 d-flex align-center">
          <v-fade-transition>
            <v-carousel
              v-show="isCarouselShown"
              v-model="selectedImage"
              height="auto"
              :show-arrows="$device.isDesktop ? 'hover' : false"
              hide-delimiters
            >
              <v-carousel-item
                v-for="image in images"
                :key="image.src"
                width="100%"
              >
                <v-sheet
                  color="white"
                  height="100%"
                  width="100%"
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-img
                    class="rounded"
                    height="554"
                    min-height="214"
                    width="100%"
                    :src="image.srcHq"
                    :alt="image.alt"
                  />
                </v-sheet>
              </v-carousel-item>
              <template #prev="{ props: prevProps }">
                <v-btn
                  v-bind="prevProps"
                  variant="tonal"
                  color="ui-kit-bg-gray-80"
                >
                  <v-icon
                    color="white"
                    icon="ui-icon-arrow-left"
                  />
                </v-btn>
              </template>
              <template #next="{ props: nextProps }">
                <v-btn
                  v-bind="nextProps"
                  variant="tonal"
                  color="ui-kit-bg-gray-80"
                >
                  <v-icon
                    color="white"
                    icon="ui-icon-arrow-right"
                  />
                </v-btn>
              </template>
            </v-carousel>
          </v-fade-transition>
          <v-sheet
            v-if="isVideoShown"
            class="d-flex justify-center flex-grow-1"
            :width="ytFrameSizes.width"
            :height="ytFrameSizes.height"
          >
            <iframe
              frameborder="0"
              width="100%"
              height="100%"
              :src="`${video.src}${videoParams}`"
            />
          </v-sheet>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { useDisplay } from 'vuetify'
import { onKeyStroke } from '@vueuse/core'
import type { IVideo } from '~/features/Home/types'

const props = defineProps({
  title: { type: String, required: true },
  images: { type: Array as PropType<{ src: string, srcHq: string, alt: string }[]>, default: () => [] },
  video: { type: Object as PropType<IVideo>, default: () => ({}) },
  isForeignAgentYear: { type: Boolean, default: false },
})

const display = useDisplay()
const $device = useDevice()

const selectedImage = ref(0)
const isCarouselShown = ref(false)
const isVideoShown = ref(false)

const isDialogShown = computed({
  get: () => isVideoShown.value || isCarouselShown.value,
  set: () => {
    isCarouselShown.value = isVideoShown.value = false
  },
})

const ytFrameSizes = computed(() => {
  if ($device.isMobileOrTablet) {
    const { width, height } = display

    return {
      height: Math.max(width.value, height.value) * 0.45, // 45% от vmax (решает проблему с узкими экранами (широкие, но не высокие), а также с горизонтальной ориентацией)
      width: '100%',
    }
  }

  return {
    height: 500,
    width: 'auto',
  }
})

const videoParams = computed(() => {
  let params = '?autoplay=1&rel=0&controls=1&showinfo=0&wmode=transparent'

  if (props.video.start) {
    params += `&start=${props.video.start}`
  }

  return params
})

function onImageClick(index: number) {
  selectedImage.value = index
  isCarouselShown.value = true
}

function onVideoClick() {
  isVideoShown.value = true
}

onKeyStroke('ArrowRight', () => {
  selectedImage.value = selectedImage.value === props.images.length - 1 ? 0 : selectedImage.value + 1
}, { eventName: 'keyup' })

onKeyStroke('ArrowLeft', () => {
  selectedImage.value = selectedImage.value === 0 ? props.images.length - 1 : selectedImage.value - 1
}, { eventName: 'keyup' })
</script>
