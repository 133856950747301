<template>
  <Mobile />
</template>

<script setup lang="ts">
import HomeApi from '~/features/Home/api'
import Mobile from '~/features/Home/Mobile.vue'

useSeoMeta({
  ogUrl: useRuntimeConfig().public.awardUrl + useRoute().fullPath,
})

const mainStore = useMainStore()

await useAsyncData(async () => {
  if (mainStore.indexPage) {
    return
  }

  mainStore.indexPage = await new HomeApi().get()
})
</script>
