<template>
  <div>
    <div class="text-center mx-15 mx-sm-0 px-4 px-sm-8">
      <div
        class="text-ui-kit-text-secondary text-landing-h6 text-sm-h5"
        v-html="selectedHowItWas.awardText"
      />
      <v-btn
        class="mt-8 mt-sm-6 text-primary"
        color="ui-kit-bg-primary"
        width="288"
        variant="flat"
        rounded
        @click="openRulesDocument(Number(howItWasYear))"
      >
        Положение о Премии {{ howItWasYear }}
      </v-btn>
    </div>

    <v-divider class="my-8 my-sm-14" />

    <div class="gr-6 gc-2 gc-sm-6 d-flex flex-wrap justify-center">
      <div
        v-for="(member, index) in selectedHowItWas.members"
        :key="member.title + index"
      >
        <v-sheet class="member text-center">
          <v-avatar
            size="56"
            rounded="circle"
          >
            <v-img
              :alt="member.title"
              :src="member.avatarSrc"
            />
          </v-avatar>
          <div class="mt-2 text-body-1">
            {{ member.title }}
            <span
              v-if="'foreignAgent' in member && member.foreignAgent"
              class="text-ui-kit-disabled"
            >
              *
            </span>
          </div>
          <div
            class="mt-1 text-body-2 text-ui-kit-text-secondary"
            v-html="member.subtitle"
          />
        </v-sheet>
      </div>
    </div>

    <v-divider class="my-8 my-sm-14" />

    <Gallery
      :title="`Галерея Премии ${howItWasYear}`"
      :images="selectedHowItWas.gallery.images"
      :video="selectedHowItWas.gallery.video"
      :is-foreign-agent-year="foreignAgentYear"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import Gallery from '~/features/Home/components/Gallery.vue'
import type { HowItWasMember } from '~/features/Home/types'
import { HOW_IT_WAS } from '~/features/Home/constants'

const props = defineProps({
  selectedHowItWas: { type: Object as PropType<typeof HOW_IT_WAS[keyof typeof HOW_IT_WAS]>, required: true },
  howItWasYear: { type: Number as PropType<keyof typeof HOW_IT_WAS>, required: true },
})

const foreignAgentYear = computed(() =>
  props.selectedHowItWas.members.some((member: HowItWasMember) => member.foreignAgent))

</script>

<style lang="scss" scoped>
@use 'vuetify/settings';

.member {
  width: 138px;

  @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
    width: 192px;
  }
}
</style>
