<template>
  <div>
    <h2 class="mb-6 mb-sm-8 text-center text-h5">
      Этапы премии {{ awardYear }}
    </h2>
    <v-row class="ga-2">
      <v-col>
        <CardStageAward
          title="Премия"
          :date-text="awardDateText"
          :button-text="awardBtnText"
          :date-icon="awardIcon"
          :img-props="{
            src: '/graphics/award-stars.png',
            alt: 'Три звезды',
            maxWidth: 376,
            maxHeight: 196
          }"
          :button-props="{
            to: { name: AWARD_ROUTE_NAMES.main, params: { year: awardYear } }
          }"
        >
          <template #content-text>
            <span>
              Определяем лучших за {{ awardYear }} год в&nbsp;каждом регионе.
            </span>
          </template>
        </CardStageAward>
      </v-col>
      <v-col>
        <CardStageAward
          title="Гран-при"
          :date-text="grandPrixDateText"
          :button-text="grandPrixBtnText"
          :date-icon="grandPrixIcon"
          :img-props="{
            src: '/graphics/grand-prix-star.png',
            alt: 'Золотая звезда',
            maxWidth: 376,
            maxHeight: 196
          }"
          :button-props="grandPrixButtonProps"
          dark
        >
          <template #content-text>
            {{ grandPrixPreviewText }}
          </template>
        </CardStageAward>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import type { TextByYears } from '~/features/Home/types'
import { AWARD_ROUTE_NAMES, GRAND_PRIX_ROUTE_NAMES } from '~/features/Home/constants'
import CardStageAward from '~/features/Home/components/CardStageAward.vue'
import {
  AWARD_DATE_TEXTS,
  GRAND_PRIX_DATE_TEXTS,
  GRAND_PRIX_PREVIEW_TEXTS,
  GRAND_PRIX_CURRENT_YEAR_STRING_CONSTS,
} from '~/features/results/constants'

const currentYear = new Date().getFullYear()
const previousYear = currentYear - 1

const mainStore = useMainStore()

const awardYear = computed(() => mainStore.awardState === 'year-start' ? previousYear : currentYear)

const awardBtnText = computed(() => mainStore.awardState === 'announcement' ? 'На страницу премии' : 'Смотреть результаты')
const awardIcon = computed(() => mainStore.awardState === 'announcement' ? 'ui-icon-clock' : 'ui-icon-check-circle')
const grandPrixIcon = computed(() => mainStore.grandPrixState === 'announcement' ? 'ui-icon-clock' : 'ui-icon-check-circle')
const isBroadcastAnnounced = computed(() => mainStore.siteSettings.enable_broadcast_announce_grand_prix)
const grandPrixBtnText = computed(() => mainStore.grandPrixState === 'announcement'
  ? isBroadcastAnnounced.value ? 'Смотреть трансляцию' : 'На страницу Гран-при'
  : 'Смотреть результаты')

const awardDateText = computed(() => {
  return getTextByAwardState(AWARD_DATE_TEXTS)
})

const grandPrixDateText = computed(() => {
  return getTextByAwardState(GRAND_PRIX_DATE_TEXTS)
})

const grandPrixPreviewText = computed(() => {
  return getTextByAwardState(GRAND_PRIX_PREVIEW_TEXTS)
})

const grandPrixButtonProps = computed(() => {
  return mainStore.grandPrixState === 'announcement' && isBroadcastAnnounced.value
    ? { href: GRAND_PRIX_CURRENT_YEAR_STRING_CONSTS.broadcastLink }
    : {
        to: {
          name: GRAND_PRIX_ROUTE_NAMES.doctor,
          params: {
            year: mainStore.awardYear,
          },
        },
      }
})

function getTextByAwardState(texts: TextByYears) {
  return texts[mainStore.awardState === 'year-start' ? previousYear : currentYear] || ''
}
</script>
